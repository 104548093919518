import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom"
import dotenv from 'dotenv'
import { isMobile } from 'react-device-detect'

import Map from './pages/Map'
import List from './pages/List'
import About from './pages/About'
import Exhibition from './pages/Exhibition'

import './styles/App.css';

dotenv.config()

const process_api_key = process.env.REACT_APP_AIRTABLE_API_KEY;
const airtable_api_url = process.env.REACT_APP_AIRTABLE_API_URL;
const airtable_base = process.env.REACT_APP_AIRTABLE_BASE;
const mapbox_token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN_PUBLIC;

const airtableProps = { process_api_key, airtable_api_url, airtable_base }
const mapProps = { mapbox_token }

const downloadURL = 'https://airtable.com/shr0SXhGW2ewowY14'
const formURL = 'https://airtable.com/shrFNjJPmUQMcWYBF'

function App() {
  const [isListShowing, setShowList] = useState(true)
  const [hideListActions, setHideListActions] = useState(false)
  const [activeRoute, setActiveRoute] = useState('')

  const handleClick = (value, route) => {
    setHideListActions(value)
    setActiveRoute(route)
  }

  return (
    <div className='app'>
      <Router>
        <Link 
          onClick={() => handleClick(false, 'list')} to="/list"><h2 className={activeRoute==='list' ? 'activeLink title' : 'title'}>The Architects Collaborative</h2></Link>
        <nav>
          <div className='column-left'>
            <ul>
              <li>
                <Link 
                  className={activeRoute === 'list' ? 'activeLink' : null}
                  onClick={() => handleClick(false, 'list')} to="/list">List</Link>
              </li>
              <li>
                <Link 
                  className={activeRoute === 'map' ? 'activeLink' : null}
                  onClick={() => handleClick(true, 'map')} to="/map">Map</Link>
              </li>
              <li>
                <Link 
                  className={activeRoute === 'about' ? 'activeLink' : null}
                  onClick={() => handleClick(true, 'about')} to="/about">About</Link>
              </li>
              <li>
                <Link 
                  className={activeRoute === 'exhibition' ? 'activeLink' : null}
                  onClick={() => handleClick(true, 'exhibition')} to="/exhibition">Exhibition</Link>
              </li>
            </ul>
          </div>
          {!hideListActions && 
            <div className='column-right'>
              <div className="list-actions">
                <button onClick={() => !isMobile ? setShowList(!isListShowing) : window.location.href = formURL }>{
                  isMobile 
                  ? 'Submit a project' 
                  : isListShowing? 'Show form' : 'Show list'
                }</button>
                <button onClick={() => window.location.href = downloadURL}>View full dataset</button>
              </div>
            </div>
          }
        </nav>

        <Switch>
          <Redirect exact from="/" to="/list" />
          <Route path="/list" render={() => <List {...airtableProps} isListShowing={isListShowing} />} />
          <Route path="/map" render={() => <Map {...mapProps} />} />
          <Route path="/about" render={() => <About {...airtableProps} />} />
          <Route path="/exhibition" render={() => <Exhibition {...airtableProps} />} />
        </Switch>

      </Router>
    </div>
  );
}

export default App
