import React, { useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate'
import { isMobile } from 'react-device-detect'

import '../styles/list.css'

const List = ({ airtable_api_url, airtable_base, process_api_key, isListShowing }) => {

  // Ref that will hold all data
  const airtableDataRef = useRef([])
  // Airtable API 
  const sortBy = 'sort%5B0%5D%5Bfield%5D=index'
  const airtable_table = 'Final'
  const [airtableOffset, setAirtableOffset] = useState('')
  const request = `${airtable_api_url}/${airtable_base}/${airtable_table}?${sortBy}&offset=${airtableOffset}`

  // Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const [numPages, setNumPages] = useState(1)
  const PER_PAGE = 100 // to make things simpler, lets stick to the max number of records we receive from Airtable, which is 100
  const paginationOffset = currentPage * PER_PAGE

  const fetchData = async (request) => {
    const res = await fetch(request, {
      method: 'GET',
      "headers": { "Authorization": `Bearer ${process_api_key}` },
      'content-type': 'application/json'
    });
    const data = await res.json();
    return data
  }

  /* 
  This is the effect that fetches data from Airtable. Due to the slightly complicated way they do pagination, we have to recursively fetch the endpoint (if there is an airtableOffset, we know there's more data to be fetched). We store everything in a reference which can be immutably accessed from the render.
  */
  useEffect(() => {
    fetchData(request)
      .then(data => {
        const { records, offset } = data
        
        if (!records) return

        if (offset) {
          setAirtableOffset(offset)
          fetchData(request)
            .then(_data => {
              airtableDataRef.current.push(_data.records)
            })
        }
        else {
          airtableDataRef.current.push(records)
        }
        setNumPages(airtableDataRef.current.length)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airtableOffset])

  // Error handling
  if (
      airtableDataRef == null 
      || airtableDataRef === undefined 
      || airtableDataRef.current === undefined 
      || airtableDataRef.current.length === 0
  ) return <div className='loading'>Loading data...</div>

  const currentEntries = airtableDataRef.current[currentPage]
  const headers = Object.keys(airtableDataRef.current[currentPage][0].fields)


  const shouldBeHidden = (el) => {
    if (
      el === 'Project' ||
      el === 'Address' ||
      el === 'Year' ||
      el === 'Partner-in-Charge' ||
      el === 'Status'
    ) return false
    return true
  }

  const determineWidth = (el) => {
    if (el === 'Project' || el === 'Partner-in-Charge' || el === 'Address') return 'isWide'
    if (el === 'Year') return 'isNarrow'
    return null
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop > e.target.scrollHeight/2) { 
      document.getElementById('list').style.cursor = 'n-resize'
    }
    else {
      document.getElementById('list').style.cursor = 's-resize'
    }
  }

  // mobile
  if (isMobile) {
    return (
      <div className='mobile-list'>
        {currentEntries.map((entry, i) => {
          const fields = entry.fields;
          return (
            <div className='mobile-list-element' key={i}>
              {Object.keys(fields).map((fieldKey, j) => {
                if (shouldBeHidden(fieldKey)) return null
                const value = fields[fieldKey]
                const classNameValue = value === 'NA' ? 'mobile-list-value NA' : 'mobile-list-value'
                const header = fieldKey
                const classNameHeader = value === 'NA' ? 'mobile-list-header NA' : 'mobile-list-header'
                return (
                  <div className='mobile-list-element-row' key={j}>
                    <div className={classNameHeader}>{header}</div>
                    <div className={classNameValue}>{value}</div>
                  </div>
                )
              })}
            </div>
          )
        })}
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={numPages}
          onPageChange={(page) => setCurrentPage(page.selected)}
          paginationOffset={paginationOffset}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link--hidden"}
          nextLinkClassName={"pagination__link--hidden"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </div>
    )
  }


  // desktop 
  return (
    <>
      {isListShowing &&
        <div>
          <div className="list-row headers">
            {headers.map((header, i) => {
              if (shouldBeHidden(header)) return null
              const widthControl = determineWidth(header)
              return (
                <div className={`list-column ${widthControl}`} key={i}>{header}</div>
              )
            })}
          </div>
          <div className="list" id="list" onScroll={ (e) => handleScroll(e)}>
            {currentEntries.map((entry, i) => {
              const fields = entry.fields;
              return (
                <div className='list-row entries' key={i}>
                  {Object.keys(fields).map((fieldKey, j) => {
                    if (shouldBeHidden(fieldKey)) return null
                    const value = fields[fieldKey]
                    const widthControl = determineWidth(fieldKey)
                    const className = value === 'NA' ? `list-column NA ${widthControl}` : `list-column ${widthControl}`
                    return (
                      <div className={className} key={j}>{value}</div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={numPages}
            onPageChange={(page) => setCurrentPage(page.selected)}
            paginationOffset={paginationOffset}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link--hidden"}
            nextLinkClassName={"pagination__link--hidden"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </div>
      }
      {!isListShowing && 
        <div>
          <iframe 
            title='iframe' 
            className="iframe" 
            src="https://airtable.com/embed/shrFNjJPmUQMcWYBF?" 
            frameborder="0" 
            width="100%" 
            height="850" 
            seamless
            style={{ background: '#fff !important', fontFamily: 'Inter', fontSize: '1rem'}}></iframe>
        </div>
      }
    </>
    
  );
}

export default List;