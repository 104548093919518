import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { isMobile } from 'react-device-detect'

import '../styles/App.css';

const Exhibition = ({ airtable_api_url, airtable_base, process_api_key }) => {
  const airtable_table = 'Content'
  const [data, setData] = useState([])
  const fetchData = async () => {
    await fetch(`${airtable_api_url}/${airtable_base}/${airtable_table}`, {
      method: 'GET',
      "headers": { "Authorization": `Bearer ${process_api_key}` },
      'content-type': 'application/json'
    }).then(res => res.json()).then(data => setData(data))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const { records } = data

  if (!data || !records) return <></>

  const exhibition = records.filter(r => r.fields.page === 'exhibition')

  return (
    <div className='page'>
      <div className={isMobile ? '' : 'column'}>
        <div>
          <h3>Information</h3>
          <main>
            <ReactMarkdown
              children={exhibition.filter(el => el.fields.Component === 'Information').map(e => e.fields.Content).toString()}
              escapeHtml={false}
            />
            </main>
        </div>
        <div>
          <h3>Links</h3>
          <main>
            <ReactMarkdown
              children={exhibition.filter(el => el.fields.Component === 'Links').map(e => e.fields.Content).toString()}
              escapeHtml={false}
            />
            </main>
        </div>
      </div>
    </div>
  );
}

export default Exhibition;