import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { isMobile } from 'react-device-detect'

import '../styles/App.css';

const About = ({ airtable_api_url, airtable_base, process_api_key }) => {
  const airtable_table = 'Content'
  const [data, setData] = useState([])
  const fetchData = async () => {
    await fetch(`${airtable_api_url}/${airtable_base}/${airtable_table}`, {
      method: 'GET',
      "headers": { "Authorization": `Bearer ${process_api_key}` },
      'content-type': 'application/json'
    }).then(res => res.json()).then(data => setData(data))
  }

  useEffect( () => {
    fetchData()
  }, [])

  const { records } = data

  if (!data || !records) return <></>

  const about = records.filter( r => r.fields.page === 'about')

  return (
    <div className='page'>
      <div className={isMobile ? '' : 'column'}>
        <div>
          <h3>The Project</h3>
          <main>
            <ReactMarkdown
              children={about.filter(el => el.fields.Component === 'Project').map(e => e.fields.Content).toString()}
              escapeHtml={false}
            />
            </main>
        </div>
        <div>
          <h3>Sponsors</h3>
          <main>
            <ReactMarkdown
              children={about.filter(el => el.fields.Component === 'Sponsors').map(e => e.fields.Content).toString()}
              escapeHtml={false}
            />
          </main>
        </div>
        <div>
          <h3>Credits</h3>
          <main>
            <ReactMarkdown
              children={about.filter(el => el.fields.Component === 'Credits').map(e => e.fields.Content).toString()}
              escapeHtml={false}
            />
          </main>
        </div>
        <div>
          <h3>Contact</h3>
          <main>
            <ReactMarkdown
              children={about.filter(el => el.fields.Component === 'Contact').map(e => e.fields.Content).toString()}
              escapeHtml={false}
            />
          </main>
        </div>
      </div>
    </div>
  );
}

export default About;